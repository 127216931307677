import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
	selector: 'input-debounce',
	template: '<input type="text" [class]="class" [placeholder]="placeholder" [(ngModel)]="inputValue" [value]="inputValue">'
})
export class InputDebounceComponent {
	@Input() placeholder: string;
	@Input() class: string;
	@Input() delay = 500;
	@Output() value = new EventEmitter();

	@Input() inputValue: string;

	constructor(private elementRef: ElementRef) {
		const eventStream = fromEvent(elementRef.nativeElement, 'input')
			.pipe(map(() => this.inputValue))
			.pipe(debounceTime(this.delay))
			.pipe(distinctUntilChanged());

		eventStream.subscribe(input => this.value.emit(input));
	}
}
