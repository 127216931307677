import {ApplicationRef, Component, ElementRef, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../environments/environment';

declare var google: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.sass']
})
export class AppComponent implements OnInit {
	constructor(
		private http: HttpClient,
		private title: Title,
		private appRef: ApplicationRef,
		private elementRef: ElementRef,
		public translate: TranslateService
	) {
		// translate.addLangs(['de', 'en', 'pl']);
		translate.addLangs(['en', 'pl']);
		translate.setDefaultLang('en');

		const browserLang = translate.getBrowserLang();

		// translate.use(browserLang.match(/de|en|pl/) ? browserLang : 'en');
		translate.use(browserLang.match(/en|pl/) ? browserLang : 'en');
	}

	env = environment.public;
	cc;
	pageName = `${this.env.projectName} - ${this.env.companyName}`;
	mapOptions = {
		center: {
			lat: 55.378769,
			lng: 21.030610
		},
		zoom: 5
	};
	routesRenderOptions = {
		preserveViewport: true,
		suppressMarkers: true
	};
	addresses = {
		sourceStart: this.env.sourceStart.address,
		destinationEnd: this.env.destinationEnd.address
	};
	descriptions = {
		sourceStart: {
			country: '',
			postal_code: ''
		},
		destinationEnd: {
			country: '',
			postal_code: ''
		}
	};
	coordinates = {
		sourceStart: {
			lat: this.env.sourceStart.lat,
			lng: this.env.sourceStart.lng
		},
		sourceEnd: this.env.sourceEnd,
		destinationStart: this.env.destinationStart,
		destinationEnd: {
			lat: this.env.destinationEnd.lat,
			lng: this.env.destinationEnd.lng
		}
	};
	errors = {
		sourceAddress: undefined,
		destinationAddress: undefined,
		weight: undefined
	};
	timeouts = {
		sourceAddress: undefined,
		sourceCoordinates: undefined,
		destinationAddress: undefined,
		destinationCoordinates: undefined,
		weight: undefined
	};
	speed = 'slow';
	weight = 140;
	size = 's';
	calculatedPrice: string;
	calculatedPriceWithTax: string;
	isAnyModalVisible = false;
	isPalletModalVisible = false;
	isOrderModalVisible = false;
	isContactModalVisible = false;
	isMapToggled = false;
	orderFormData;
	contactFormData;
	orderFormResult = '';
	contactFormResult = '';
	orderFormErrors = [];
	contactFormErrors = [];
	selectedCountries = {
		import: 'PL',
		export: 'FI'
	};

	getSpeed() {
		switch (this.speed) {
			case 'slow':
				return 'Standard, 5-7 dni roboczych';
			case 'fast':
				return 'Express, 48-72 godziny';
		}
	}

	getSize() {
		switch (this.size) {
			case 's':
				return '1-3 palet';
			case 'm':
				return '4-5 palet';
			case 'l':
				return '6-10 palet';
		}
	}

	getCountry(addressComponents) {
		for (let i = 0; i < addressComponents.length; i++) {
			if (
				addressComponents[i].types[0] == 'country' &&
				addressComponents[i].types[1] == 'political'
			) {
				return addressComponents[i].long_name;
			}
		}
		return this.translate.instant('none');
	}

	getPostalCode(addressComponents) {
		for (let i = 0; i < addressComponents.length; i++) {
			if (
				addressComponents[i].types[0] == 'postal_code'
			) {
				return addressComponents[i].long_name;
			}
		}
		return this.translate.instant('none');
	}

	isCountryCorrect(direction, addressComponents) {
		for (let i = 0; i < addressComponents.length; i++) {
			if (
				addressComponents[i].types[0] == 'country' &&
				addressComponents[i].types[1] == 'political'
			) {
				return this.selectedCountries[direction] === addressComponents[i].short_name;
			}
		}
		return false;
	}

	getCountryNameWithPostfix(direction, postfix = '') {
		for (let i = 0; i < this.cc.countries[direction].length; i++) {
			if (this.cc.countries[direction][i].code === this.selectedCountries[direction]) {
				return `${this.translate.instant(this.cc.countries[direction][i].name)}${postfix}`;
			}
		}

		return '';
	}

	updateRoutes() {
		this.coordinates.sourceStart = {
			lat: this.coordinates.sourceStart.lat,
			lng: this.coordinates.sourceStart.lng
		};
		this.coordinates.destinationEnd = {
			lat: this.coordinates.destinationEnd.lat,
			lng: this.coordinates.destinationEnd.lng
		};
	}

	weightChanged() {
		clearTimeout(this.timeouts.weight);

		this.timeouts.sourceAddress = setTimeout(() => {
			if (!this.areThereErrors()) {
				this.calculateDistance();

				this.appRef.tick();
			}
		}, 1500);
	}

	sourceAddressChanged() {
		clearTimeout(this.timeouts.sourceAddress);

		this.timeouts.sourceAddress = setTimeout(() => {
			new google.maps.Geocoder().geocode({
				address: this.getCountryNameWithPostfix('import', ', ') + this.addresses.sourceStart
			}, (coordinatesResponse, status) => {
				switch (status) {
					case 'OK':
						if (this.isCountryCorrect('import', coordinatesResponse[0].address_components)) {
							this.errors.sourceAddress = undefined;
						} else {
							this.errors.sourceAddress = this.translate.instant('addressIsNotInThisCountry');
						}

						this.coordinates.sourceStart = {
							lat: coordinatesResponse[0].geometry.location.lat(),
							lng: coordinatesResponse[0].geometry.location.lng()
						};
						this.descriptions.sourceStart.country = this.getCountry(coordinatesResponse[0].address_components);
						this.descriptions.sourceStart.postal_code = this.getPostalCode(coordinatesResponse[0].address_components);

						break;
					case 'ZERO_RESULTS':
						this.errors.sourceAddress = this.translate.instant('noMatchingResults');

						break;
					default:
						this.errors.sourceAddress = this.translate.instant('errorInSearch');
				}

				this.appRef.tick();
			});
		}, 1500);
	}

	sourceCoordinatesChanged() {
		clearTimeout(this.timeouts.sourceCoordinates);

		this.timeouts.sourceCoordinates = setTimeout(() => {
			new google.maps.Geocoder().geocode({
				location: this.coordinates.sourceStart
			}, (addressResponse, status) => {
				switch (status) {
					case 'OK':
						if (this.isCountryCorrect('import', addressResponse[0].address_components)) {
							this.errors.sourceAddress = undefined;

						} else {
							this.errors.sourceAddress = this.translate.instant('addressIsNotInThisCountry');
						}

						this.addresses.sourceStart = addressResponse[0].formatted_address;
						this.descriptions.sourceStart.country = this.getCountry(addressResponse[0].address_components);
						this.descriptions.sourceStart.postal_code = this.getPostalCode(addressResponse[0].address_components);

						this.updateRoutes();

						break;
					case 'ZERO_RESULTS':
						this.errors.sourceAddress = this.translate.instant('noMatchingResults');

						break;
					default:
						this.errors.sourceAddress = this.translate.instant('errorInSearch');
				}

				this.appRef.tick();
			});
		}, 1500);
	}

	destinationAddressChanged() {
		clearTimeout(this.timeouts.destinationAddress);

		this.timeouts.destinationAddress = setTimeout(() => {
			new google.maps.Geocoder().geocode({
				address: this.getCountryNameWithPostfix('export', ', ') + this.addresses.destinationEnd
			}, (coordinatesResponse, status) => {
				switch (status) {
					case 'OK':
						if (this.isCountryCorrect('export', coordinatesResponse[0].address_components)) {
							this.errors.destinationAddress = undefined;
						} else {
							this.errors.destinationAddress = this.translate.instant('addressIsNotInThisCountry');
						}

						this.coordinates.destinationEnd = {
							lat: coordinatesResponse[0].geometry.location.lat(),
							lng: coordinatesResponse[0].geometry.location.lng()
						};
						this.descriptions.destinationEnd.country = this.getCountry(coordinatesResponse[0].address_components);
						this.descriptions.destinationEnd.postal_code = this.getPostalCode(coordinatesResponse[0].address_components);

						break;
					case 'ZERO_RESULTS':
						this.errors.destinationAddress = this.translate.instant('noMatchingResults');

						break;
					default:
						this.errors.destinationAddress = this.translate.instant('errorInSearch');
				}

				this.appRef.tick();
			});
		}, 1500);
	}

	destinationCoordinatesChanged() {
		clearTimeout(this.timeouts.destinationCoordinates);

		this.timeouts.destinationCoordinates = setTimeout(() => {
			new google.maps.Geocoder().geocode({
				location: this.coordinates.destinationEnd
			}, (addressResponse, status) => {
				switch (status) {
					case 'OK':
						if (this.isCountryCorrect('export', addressResponse[0].address_components)) {
							this.errors.destinationAddress = undefined;

						} else {
							this.errors.destinationAddress = this.translate.instant('addressIsNotInThisCountry');
						}

						this.addresses.destinationEnd = addressResponse[0].formatted_address;
						this.descriptions.destinationEnd.country = this.getCountry(addressResponse[0].address_components);
						this.descriptions.destinationEnd.postal_code = this.getPostalCode(addressResponse[0].address_components);

						this.updateRoutes();

						break;
					case 'ZERO_RESULTS':
						this.errors.destinationAddress = this.translate.instant('noMatchingResults');

						break;
					default:
						this.errors.destinationAddress = this.translate.instant('errorInSearch');
				}

				this.appRef.tick();
			});
		}, 1500);
	}

	sourceStartMarkerDragEnd(e) {
		this.coordinates.sourceStart = {
			lat: e.coords.lat,
			lng: e.coords.lng
		};

		this.sourceCoordinatesChanged();
	}

	destinationEndMarkerDragEnd(e) {
		this.coordinates.destinationEnd = {
			lat: e.coords.lat,
			lng: e.coords.lng
		};

		this.destinationCoordinatesChanged();
	}

	checkWeight() {
		if (this.weight > this.cc.weightLimit) {
			this.errors.weight = this.translate.instant('tooMuchWeight');
		} else {
			this.errors.weight = undefined;

			this.weightChanged();
		}
	}

	variablesChanged() {
		if (!this.areThereErrors())
			this.calculateDistance();
	}

	areThereErrors() {
		for (let key in this.errors) {
			if (undefined !== this.errors[key]) {
				return true;
			}
		}

		return this.coordinates.sourceStart.lat === null ||
			this.coordinates.destinationEnd.lat === null;
	}

	calculateDistance() {
		let sourceStart = new google.maps.LatLng(this.coordinates.sourceStart.lat, this.coordinates.sourceStart.lng),
			sourceEnd = new google.maps.LatLng(this.coordinates.sourceEnd.lat, this.coordinates.sourceEnd.lng),
			destinationStart = new google.maps.LatLng(this.coordinates.destinationStart.lat, this.coordinates.destinationStart.lng),
			destinationEnd = new google.maps.LatLng(this.coordinates.destinationEnd.lat, this.coordinates.destinationEnd.lng);

		this.updateRoutes();

		new google.maps.DistanceMatrixService().getDistanceMatrix({
			origins: [sourceStart],
			destinations: [sourceEnd],
			travelMode: 'DRIVING'
		}, (sourceResponse) => {
			new google.maps.DistanceMatrixService().getDistanceMatrix({
				origins: [destinationStart],
				destinations: [destinationEnd],
				travelMode: 'DRIVING'
			}, (destinationResponse) => {
				let sourceDistance = sourceResponse.rows[0].elements[0].distance.value / 1000;
				let destinationDistance = destinationResponse.rows[0].elements[0].distance.value / 1000;
				let weightSize = this.size;

				switch (this.size) {
					case 's':
						if (
							this.weight > 3 * this.cc.palletWeight &&
							this.weight <= 5 * this.cc.palletWeight
						)
							weightSize = 'm';
						else if (this.weight > 5 * this.cc.palletWeight)
							weightSize = 'l';

						break;
					case 'm':
						if (this.weight > 5 * this.cc.palletWeight)
							weightSize = 'l';

						break;
				}

				let price = (
					this.cc.transferDistance +
					sourceDistance * this.cc.importFactor +
					destinationDistance * this.cc.exportFactor
				) * this.cc.sizeTimeFactor[this.speed][weightSize];

				this.calculatedPrice = price.toLocaleString('pl-PL', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				});
				this.calculatedPriceWithTax = (price * 1.23).toLocaleString('pl-PL', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}) + ' ' + this.translate.instant('withTax');

				this.appRef.tick();

				let asideElement = this.elementRef.nativeElement.querySelector('aside');

				asideElement.scrollBy({
					top: asideElement.scrollHeight,
					behavior: 'smooth'
				});

				this.appRef.tick();
			});

			this.appRef.tick();
		});
	}

	showPalletModal(e) {
		e.preventDefault();

		this.isAnyModalVisible = true;
		this.isPalletModalVisible = true;
	}

	showOrderModal(e) {
		e.preventDefault();

		this.isAnyModalVisible = true;
		this.isOrderModalVisible = true;

		this.appRef.tick();
	}

	showContactModal(e) {
		e.preventDefault();

		this.isAnyModalVisible = true;
		this.isContactModalVisible = true;
	}

	closeModals(e) {
		e.preventDefault();

		this.isAnyModalVisible = false;
		this.isPalletModalVisible = false;
		this.isOrderModalVisible = false;
		this.isContactModalVisible = false;

		this.contactFormResult = '';
		this.contactFormErrors = [];
		this.contactFormData.reset();
		this.orderFormResult = '';
		this.orderFormErrors = [];
		this.orderFormData.reset();

		this.appRef.tick();
	}

	ngOnInit() {
		this.title.setTitle(this.pageName);

		this.http.get(`${this.env.apiUrl}/variables/1`).toPromise().then((response: any) => {
			let r = response.data.attributes;

			this.cc = {
				countries: {
					import: r.import_countries,
					export: r.export_countries
				},
				palletWeight: r.pallet_weight,
				weightLimit: r.pallet_weight_limit,
				transferDistance: r.transfer_distance,
				importFactor: r.import_factor,
				exportFactor: r.export_factor,
				sizeTimeFactor: {
					slow: {
						s: r.slow_s,
						m: r.slow_m,
						l: r.slow_l
					},
					fast: {
						s: r.fast_s,
						m: r.fast_m,
						l: r.fast_l
					}
				}
			};
		}).catch(console.error);

		this.orderFormData = new FormGroup({
			name: new FormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(2),
				Validators.maxLength(512)
			])),
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.maxLength(512)
			])),
			phone: new FormControl('', Validators.compose([
				Validators.maxLength(512)
			])),
			description: new FormControl('', Validators.compose([
				Validators.maxLength(2048)
			])),
			acceptRules: new FormControl(false, Validators.compose([
				Validators.required,
				Validators.requiredTrue
			]))
		});

		this.contactFormData = new FormGroup({
			name: new FormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(2),
				Validators.maxLength(512)
			])),
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.maxLength(512)
			])),
			phone: new FormControl('', Validators.compose([
				Validators.maxLength(512)
			])),
			addCalculations: new FormControl(true),
			description: new FormControl('', Validators.compose([
				Validators.maxLength(2048)
			])),
			acceptRules: new FormControl(false, Validators.compose([
				Validators.required,
				Validators.requiredTrue
			]))
		});

		setInterval(() => {
			this.appRef.tick();
		}, 500);
	}

	submitOrderForm(data) {
		let body = new URLSearchParams();

		body.append('subject', '[Kalkulator] Wiadomość z formularza zamówienia');
		body.append('name', data.name);
		body.append('email', data.email);
		body.append('phone', data.phone);
		body.append('description', data.description);
		body.append('acceptRules', data.acceptRules);
		body.append('calculations', `
Miejsce odbioru: ${this.addresses.sourceStart} (${this.coordinates.sourceStart.lat}, ${this.coordinates.sourceStart.lng})
Miejsce dostawy: ${this.addresses.destinationEnd} (${this.coordinates.destinationEnd.lat}, ${this.coordinates.destinationEnd.lng})
Szybkość dostawy: ${this.getSpeed()}
Waga: ${this.weight} kg
Ilość palet: ${this.getSize()}
Wyliczona cena: ${this.calculatedPrice} (${this.calculatedPriceWithTax})
			`);

		this.http.post('/assets/contact-form/contact.php', body.toString(), {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			})
		}).toPromise().then((response: any) => {
			if (response.result) {
				this.orderFormData.reset();
				this.orderFormResult = 'Wiadomość została prawidłowo wysłana, wkrótce się z Tobą skontaktujemy.';
			} else {
				this.orderFormErrors = response.errors;
			}

			this.appRef.tick();
		}).catch(() => {
			this.orderFormErrors = [
				'Wystąpił błąd przy wysyłce wiadomości, prosimy spróbować później.'
			];

			this.appRef.tick();
		});
	}

	submitContactForm(data) {
		let body = new URLSearchParams();

		body.set('subject', '[Kalkulator] Wiadomość z formularza zapytania o niestandardową przesyłkę');
		body.set('name', data.name);
		body.set('email', data.email);
		body.set('phone', data.phone);
		body.set('description', data.description);
		body.set('acceptRules', data.acceptRules);

		if (data.addCalculations) {
			body.set('calculations', `
Miejsce odbioru: ${this.addresses.sourceStart} (${this.coordinates.sourceStart.lat}, ${this.coordinates.sourceStart.lng})
Miejsce dostawy: ${this.addresses.destinationEnd} (${this.coordinates.destinationEnd.lat}, ${this.coordinates.destinationEnd.lng})
Szybkość dostawy: ${this.getSpeed()}
Waga: ${this.weight} kg
Ilość palet: ${this.getSize()}
			`);
		}

		this.http.post('/assets/contact-form/contact.php', body.toString(), {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			})
		}).toPromise().then((response: any) => {
			if (response.result) {
				this.contactFormData.reset();
				this.contactFormResult = 'Wiadomość została prawidłowo wysłana, wkrótce się z Tobą skontaktujemy.';
			} else {
				this.contactFormErrors = response.errors;
			}
		}).catch(() => {
			this.contactFormErrors = [
				'Wystąpił błąd przy wysyłce wiadomości, prosimy spróbować później.'
			];
		});
	}
}
