// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	googleMapsApiKey: 'AIzaSyAB_AtoTiyK3mFgstMt6zGs-T7F7fJrbh0',
	public: {
		currentYear: new Date().getFullYear(),
		companyName: 'KAEL TRANSPORT | Finlandia',
		projectName: 'Kalkulator',
		apiUrl: 'https://panel.calc.finlandia.kaeltransport.pl/api',
		sourceStart: {
			lat: null,
			lng: null,
			address: ''
		},
		sourceEnd: {
			lat: 53.151445,
			lng: 18.124074
		},
		destinationStart: {
			lat: 60.168884,
			lng: 24.9357746
		},
		destinationEnd: {
			lat: null,
			lng: null,
			address: ''
		}
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
